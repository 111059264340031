@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;

.header {
  @include mainContentStyles;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 14px;

    .title-block {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 42px;

      @include min-428-break {
        gap: 12px;
        height: 52px;
      }

      .title {
        @include typography-m;
        @include weight-semi-bold;

        @include min-428-break {
          @include typography-l;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    @include min-428-break {
      gap: 12px;
    }
  }
}
