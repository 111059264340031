@use '@button' as *;
@use '@queries' as *;

.bottom-bar {
  background-color: var(--global-white);
  box-shadow: var(--elevation-2);
  border-bottom: 1px;
  padding: 12px 14px 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 16px 28px;
  }

  .button {
    @include buttonSizes('38', '46', '52', '52', '52', '52');
  }
}
