@use '@queries' as *;

.container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.empty {
  padding: 20px 0;
  align-self: center;
  display: flex;
  min-width: 100%;
  @include min-428-break {
    padding: 34px 0;
  }
  @include min-744-break {
    padding: 46px 0;
  }
}
