@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
.slide {
  position: relative;
  border-radius: var(--cornerradius14);
  background: #dcdcdc center;
  background-size: cover;
  overflow: hidden;

  @include min-744-break {
    border-radius: var(--cornerradius16);
  }

  &-l {
    aspect-ratio: 292/136;

    @include min-428-break {
      aspect-ratio: 405/206;
    }
  }

  &-m {
    aspect-ratio: 292/116;

    @include min-428-break {
      aspect-ratio: 400/156;
    }
  }
}

.img {
  display: block;
  object-fit: cover;
  border-radius: inherit;
}

.plate {
  width: 95%;
  aspect-ratio: 292/136;
  @include min-428-break {
    aspect-ratio: 400/206;
  }
  @include min-744-break {
    width: 100%;
    aspect-ratio: 744/206;
  }
}
