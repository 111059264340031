@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;

.content-widget {
  &__live-feed-container {
    @include mainContentStyles;

    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 0;
    @include min-428-break {
      padding: 14px 0;
    }
    .label {
      padding-left: 12px;
      @include min-428-break {
        padding-left: 14px;
      }
      @include min-744-break {
        padding-left: 28px;
      }
      span:has(svg),
      svg {
        color: #6941c6;
      }
    }
  }

  &__live-feed {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    min-height: 58px;

    @include hideScrollbar;

    article:nth-of-type(1) {
      margin-left: 12px;

      @include min-428-break {
        margin-left: 14px;
      }

      @include min-744-break {
        margin-left: 28px;
      }
    }

    article:last-child {
      margin-right: 12px;

      @include min-428-break {
        margin-right: 14px;
      }

      @include min-744-break {
        margin-right: 28px;
      }
    }

    @include min-428-break {
      gap: 8px;
    }

    @include min-744-break {
      gap: 10px;
    }

    &-sheet-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 6px;

      @include min-428-break {
        gap: 8px;
      }

      @include min-744-break {
        min-width: 400px;
      }

      .icon {
        @include singleFixedSize(16px);

        @include min-428-break {
          @include singleFixedSize(20px);
        }
      }
    }

    &-sheet-btns {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 6px;

      @include min-428-break {
        gap: 8px;
      }

      span[id='btn-icon'],
      svg {
        @include singleFixedSize(18px);

        @include min-428-break {
          @include singleFixedSize(20px);
        }

        @include min-744-break {
          @include singleFixedSize(22px);
        }
      }
    }
  }
}
