@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @include min-428-break {
    gap: 14px;
  }

  .text {
    @include typography-base;
    letter-spacing: 0.4px;
    @include min-428-break {
      @include typography-m;
    }
  }
}
