@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;
.history {
  @include mainContentStyles;
  margin: 20px 0;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }
}

.history-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .history-counter {
    @include typography-s;
    @include weight-semi-bold;
    color: var(--cst-label-primary-text-default);
    opacity: 0.4;

    @include min-428-break {
      @include typography-m;
    }
  }
}

.label {
  span:has(svg),
  svg {
    color: #6941c6;
  }
}
