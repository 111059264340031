@use '@queries' as *;

.modal {
  max-height: calc(100% - env(safe-area-inset-top)) !important;
  height: calc(100% - env(safe-area-inset-top)) !important;
  border-radius: 0 !important;

  > div {
    border-radius: 0 !important;
  }

  @include min-744-break {
    height: max-content !important;
    width: 428px;
    border-radius: 16px !important;
  }
}
