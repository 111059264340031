@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;

.content-widget {
  &__banner {
    @include mainContentStyles;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    @include fixedSize(100%, max-content);
    min-height: 174px;

    @include min-428-break {
      padding: 14px 0;

      min-height: 254px;
    }
  }
}
